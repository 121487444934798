import React from 'react'

import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import FeatureCard4 from '../components/feature-card4'
import TestimonialCard1 from '../components/testimonial-card1'
import './accueil.css'

const Accueil = (props) => {
  return (
    <div className="accueil-container">
      <Helmet>
        <title>Phenom - Reconnaissance vocale au service du médical</title>
        <meta
          property="og:title"
          content="Phenom - Reconnaissance vocale au service du médical"
        />
      </Helmet>
      <header id="header" data-role="Header" className="accueil-header">
        <img
          alt="logo"
          src="/playground_assets/logo-banner-1500h.png"
          className="accueil-image"
        />
        <div className="accueil-nav">
          <NavigationLinks rootClassName="rootClassName10"></NavigationLinks>
        </div>
        <div className="accueil-btn-group">
          <a href="#contact" className="accueil-contact button">
            Contact
          </a>
          <a
            href="https://app.phenomsystems.fr/"
            name="connexion"
            target="_blank"
            rel="noreferrer noopener"
            className="accueil-connexion button main"
          >
            Connexion
          </a>
        </div>
        <div data-role="BurgerMenu" className="accueil-burger-menu">
          <svg viewBox="0 0 1024 1024" className="accueil-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="accueil-mobile-menu">
          <div className="accueil-nav1">
            <div className="accueil-container01">
              <img
                alt="image"
                src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                className="accueil-image1"
              />
              <div data-role="CloseMobileMenu" className="accueil-menu-close">
                <svg viewBox="0 0 1024 1024" className="accueil-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks rootClassName="rootClassName11"></NavigationLinks>
          </div>
          <div>
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="accueil-icon04"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="accueil-icon06"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="accueil-icon08"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <section id="accueil" className="accueil-hero">
        <h1 className="accueil-text">
          Votre temps est plus précieux auprès de vos patients que sur votre
          ordinateur ?
        </h1>
        <span className="accueil-text01">Phenom vous comprend</span>
        <div className="accueil-btn-group1">
          <a href="#contact" className="accueil-link button">
            Contactez-nous
          </a>
        </div>
      </section>
      <section id="mission" className="accueil-banner">
        <h1 className="accueil-text02 Heading">
          <span>
            Phenom : le spécialiste de la reconnaissance vocale au service du
            médical
          </span>
          <br></br>
        </h1>
      </section>
      <div className="accueil-features">
        <div className="accueil-container02">
          <div className="accueil-container03">
            <h2 className="accueil-text05">
              <span className="accueil-text06">Gain de temps</span>
              <br></br>
            </h2>
            <span className="accueil-text08">
              Minimisez votre temps de saisie, maximisez votre temps médical
            </span>
          </div>
          <div className="accueil-container04">
            <h2 className="accueil-text09">Technologie brevetée</h2>
            <span className="accueil-text10">
              50x plus puissant que la concurrence, hyper spécialisation pour
              votre spécialité
            </span>
          </div>
          <div className="accueil-container05">
            <h2 className="accueil-text11">Outil nouvelle génération</h2>
            <span className="accueil-text12">
              <span>
                {' '}
                Disponible et synchronisé sur tous vos appareils : PC, Mac,
                Android et iOS. 
              </span>
              <span>Partage en un clic avec tous vos collaborateurs</span>
              <br></br>
            </span>
          </div>
          <div className="accueil-container06">
            <h2 className="accueil-text16">
              <span className="accueil-text17">Economique</span>
              <br></br>
            </h2>
            <span className="accueil-text19">
              Retour sur investissement immédait
            </span>
          </div>
        </div>
      </div>
      <section className="accueil-container07">
        <section id="solution" className="accueil-features1">
          <div className="accueil-container08">
            <h1 className="accueil-text20 Heading">Notre solution</h1>
            <div className="accueil-separator"></div>
          </div>
          <div className="accueil-container09">
            <div className="accueil-container10">
              <FeatureCard4
                title="Performance"
                image_alt="Performance"
                image_src="/playground_assets/performance.svg"
                description="Reconnaissance vocale précise et sans faute d’orthographe"
                rootClassName="rootClassName1"
              ></FeatureCard4>
              <FeatureCard4
                title="Intégration"
                image_alt="Intégration"
                image_src="/playground_assets/integration.svg"
                description="Compatible avec tous vos outils sur PC, Mac, Android et IOS."
                rootClassName="rootClassName4"
              ></FeatureCard4>
              <FeatureCard4
                title="Personnalisation"
                image_alt="Personnalisation"
                image_src="/playground_assets/personnalisation.svg"
                description="Algorithme qui intègre votre vocabulaire, vos raccourcis et vos abréviations."
                rootClassName="rootClassName2"
              ></FeatureCard4>
              <FeatureCard4
                title="Sécurité"
                image_alt="Sécurité"
                image_src="/playground_assets/securite.svg"
                description="Données cryptées et hébergement sécurisé"
                rootClassName="rootClassName3"
              ></FeatureCard4>
            </div>
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1638202993928-7267aad84c31?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQyfHxkb2N0b3IlMjBwaG9uZXxlbnwwfHx8fDE2NjY3OTc0MTA&amp;ixlib=rb-4.0.3&amp;w=500"
              className="accueil-image2"
            />
          </div>
        </section>
        <div className="accueil-banner-container">
          <div className="accueil-banner1">
            <div className="accueil-container11">
              <h2 className="accueil-text21">
                <span>Essayez Phenom gratuitement pendant 30 jours</span>
                <br className="accueil-text23"></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </h2>
              <a
                href="mailto:contact@phenomsystems.fr?subject=Essayez Phenom gratuitement"
                className="accueil-link1 button"
              >
                Essayer
              </a>
            </div>
          </div>
        </div>
        <div className="accueil-testimonial">
          <div className="accueil-container12">
            <div className="accueil-container13">
              <h1 className="accueil-text25 Heading">
                Phenom au service de la recherche
              </h1>
              <div className="accueil-separator1"></div>
            </div>
            <div className="accueil-container14">
              <TestimonialCard1
                name="Antoine"
                role="Psychiatre"
                quote="La technologie Phenom ouvre des perspectives de recherche pouvant révolutionner la psychiatrie, là où la parole constitue la matière première du diagnostic."
                picture_src="https://images.unsplash.com/photo-1557053910-d9eadeed1c58?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
                rootClassName="rootClassName2"
              ></TestimonialCard1>
              <TestimonialCard1
                name="Vincent"
                role="Urgentiste"
                quote="Transcrire avec Phenom les appels téléphoniques reçus aux urgences médicales, et les analyser à la volée, permettrait de mieux qualifier, trier et prioriser de départ des ambulances."
                rootClassName="rootClassName"
              ></TestimonialCard1>
              <TestimonialCard1
                name="Jean-Paul"
                role="Radiologue"
                quote="Phenom permet de constituer des bases de données pour la recherche médicale dans toutes les spécialités."
                picture_src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHdvbWFuJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUxOTgy&amp;ixlib=rb-1.2.1&amp;h=1200"
                rootClassName="rootClassName1"
              ></TestimonialCard1>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="accueil-banner2">
        <h1 className="accueil-text26 Heading">Contact</h1>
        <a
          href="mailto:contact@phenomsystems.fr?subject=Contact Phenom"
          className="accueil-link2"
        >
          contact@phenomsystems.fr
        </a>
      </section>
      <footer className="accueil-footer">
        <img
          alt="logo"
          src="/playground_assets/logo-banner-1500h.png"
          className="accueil-image3"
        />
        <span className="accueil-text27">© 2022 Phenom</span>
      </footer>
    </div>
  )
}

export default Accueil
