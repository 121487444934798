import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links.css'

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <span className="navigation-links-text">{props.text}</span>
      <span className="navigation-links-text1">{props.text1}</span>
      <span className="navigation-links-text2">{props.text2}</span>
      <span className="navigation-links-text3">{props.text3}</span>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  link_text3: '',
  link_text2: '',
  text1: 'Mission',
  link_text1: 'https://www.phenomsystems.fr/#mission',
  text: 'Accueil',
  text2: 'Solution',
  rootClassName: '',
  link_text: 'https://www.phenomsystems.fr/#accueil',
  text3: 'Contact',
}

NavigationLinks.propTypes = {
  link_text3: PropTypes.string,
  link_text2: PropTypes.string,
  text1: PropTypes.string,
  link_text1: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
  link_text: PropTypes.string,
  text3: PropTypes.string,
}

export default NavigationLinks
